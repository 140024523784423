import {
    ACTIVATE_CHAT,
    DEACTIVATE_CHAT,
    DISABLE_CHAT,
    ENABLE_CHAT,
    ACTIVATE_GPS,
    DISABLE_GPS,
    ENABLE_GPS,
    DEACTIVATE_GPS,
    ACTIVATE_VIDEO,
    DISABLE_VIDEO,
    ENABLE_VIDEO,
    DEACTIVATE_VIDEO,
    DEACTIVATE_SNAPSHOT,
    ACTIVATE_SNAPSHOT,
    ENABLE_SNAPSHOT,
    DISABLE_SNAPSHOT,
    DEACTIVATE_POINTER,
    ACTIVATE_POINTER,
    ENABLE_POINTER,
    DISABLE_POINTER,
    CALLER_CHAT_SHOW,
    CALLER_CHAT_HIDE,
    ADD_VIDEO_STREAM,
    REMOVE_VIDEO_STREAM,
    RESET_ALL,
    ADD_MEDIA_DEVICES,
    CHANGE_DEVICE_ID,
    REMOVE_DEVICE_ID,
    SHOW_REFRESH,
    PAUSE_SNAPSHOT,
    UNPAUSE_SNAPSHOT,
    DEACTIVATE_HD_SEND,
    ACTIVATE_HD_SEND,
    CALLER_FILE_TRANSFER_STARTED,
    CALLER_FILE_TRANSFER_ENDED,
    CALLER_FILE_IS_BUSY,
    CALLER_FILE_IS_NOT_BUSY,
    ENABLE_DRAW,
    DISABLE_DRAW,
    DEACTIVATE_DRAW,
    ACTIVATE_DRAW,
    ENABLE_CONFERENCING,
    DISABLE_CONFERENCING,
    ENABLE_AUDIO_STREAM,
    DISABLE_AUDIO_STREAM,
    DEACTIVATE_AUDIO_STREAM,
    ACTIVATE_AUDIO_STREAM,
    ENABLE_BIDI,
    DISABLE_BIDI,
    ACTIVATE_BIDI,
    DEACTIVATE_BIDI,
    UNMUTE_AUDIO,
    MUTE_AUDIO,
    MUTE_CALLER_MIC,
    UNMUTE_CALLER_MIC,
    CALLER_PAGE_LOADED,
    CALLER_PAGE_NOT_LOADED,
    CALLER_END_PAGE_LOADED,
    CALLER_END_PAGE_NOT_LOADED,
    ACTIVATE_NOTES,
    DEACTIVATE_NOTES,
    ENABLE_STREAM_RECORDING,
    DISABLED_STREAM_RECORDING,
    ACTIVATE_STREAM_RECORDING,
    DEACTIVATE_STREAM_RECORDING,
    ENABLE_AUTO_STREAM_RECORDING,
    DISABLE_AUTO_STREAM_RECORDING,
    START_STREAM_RECORDING,
    STOP_STREAM_RECORDING,
    CHANGE_DEVICE_NAME,
    REMOVE_DEVICE_NAME,
    ACTIVATE_PHOTO_AUTO_DOWNLOAD,
    DEACTIVATE_PHOTO_AUTO_DOWNLOAD,
    SET_PASSWORD_CHANGE_SENT,
    ACTIVATE_BIDI_BLUR,
    DEACTIVATE_BIDI_BLUR,
    ACTIVATE_BIDI_BACKGROUND,
    DEACTIVATE_BIDI_BACKGROUND,
    ACTIVATE_WEB_RTC,
    DEACTIVATE_WEB_RTC,
    ENABLE_SCREEN_SHARE,
    DISABLE_SCREEN_SHARE,
    ACTIVATE_SCREEN_SHARE,
    DEACTIVATE_SCREEN_SHARE,
    OPEN_INVITATION_PANEL,
    CLOSE_INVITATION_PANEL,
    SET_LIVE_VIDEO_IS_LOADING,
    UNSET_LIVE_VIDEO_IS_LOADING,
    ACTIVATE_CONFERENCE_CHAT,
    DEACTIVATE_CONFERENCE_CHAT,
    ACTIVATE_EXTERNAL_STREAM,
    DEACTIVATE_EXTERNAL_STREAM,
    ACTIVATE_SMART_CONNECT,
    DEACTIVATE_SMART_CONNECT,
    CALLER_TAB_IN_FOCUS,
    CALLER_TAB_NOT_IN_FOCUS,
    ACTIVATE_CALLER_FILE_TRANSFER,
    DEACTIVATE_CALLER_FILE_TRANSFER,
} from '../actions/actionTypes';

export const initialState = {
    videoIsDisabled: false,
    videoIsActive: false,
    videoIsLoading: false,
    hasVideoStream: false,
    gpsIsDisabled: false,
    gpsIsActive: false,
    snapshotIsDisabled: false,
    snapshotIsActive: false,
    snapshotPaused: false,
    pointerIsDisabled: false,
    pointerIsActive: false,
    chatIsDisabled: false,
    chatIsActive: false,
    wasChatActiveBefore: false,
    hdSendIsActive: false,
    refreshButtonShown: false,
    drawIsDisabled: false,
    drawIsActive: false,
    conferencingIsDisabled: false,
    audioStreamIsDisabled: false,
    audioStreamIsActive: false,
    audioIsMuted: false,
    bidiIsDisabled: false,
    bidiIsActive: false,
    bidiBlurIsActive: false,
    bidiBackgroundIsActive: false,
    notesIsActive: false,
    streamRecordingIsDisabled: false,
    streamRecordingIsActive: false,
    streamRecordingHasStarted: false,
    screenShareIsDisabled: false,
    screenshareIsActive: false,
    autoRecordingIsDisabled: false,
    callerChatIsShown: false,
    callerFileIsBusy: false,
    callerFileTransfering: false,
    callerFileTransferIsActive: false,
    externalStreamIsActive: false,
    smartConnectIsActive: false,
    callerMicIsMuted: false,
    callerPageLoaded: false,
    callerEndPageLoaded: false,
    callerTabIsInFocus: false,
    webRtcIsActive: false,
    preventPhotoAutoDownload: false,
    passwordChangeSent: false,
    invitationPanelIsOpen: false,
    deviceId: null,
    deviceName: null,
    devices: [],
    conferenceChatIsShown: false,
    conferenceChatIsActive: false,
};

/**
 * application reducer
 * see https://redux.js.org/
 * @param {object} state
 * @param {object} action
 */

const applicationReducer = (state = initialState, action) => {
    if (action.type === ENABLE_CHAT) {
        state = { ...state, chatIsDisabled: false };
    }

    if (action.type === DISABLE_CHAT) {
        state = { ...state, chatIsDisabled: true };
    }

    if (action.type === DEACTIVATE_CHAT) {
        state = {
            ...state,
            chatIsActive: false,
            wasChatActiveBefore: state.chatIsActive,
        };
    }

    if (action.type === ACTIVATE_CHAT) {
        state = { ...state, chatIsActive: true };
    }

    if (action.type === ENABLE_VIDEO) {
        state = { ...state, videoIsDisabled: false };
    }

    if (action.type === DISABLE_VIDEO) {
        state = { ...state, videoIsDisabled: true };
    }

    if (action.type === DEACTIVATE_VIDEO) {
        state = { ...state, videoIsActive: false };
    }

    if (action.type === ACTIVATE_VIDEO) {
        state = { ...state, videoIsActive: true };
    }

    if (action.type === ENABLE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsDisabled: false };
    }

    if (action.type === DISABLE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsDisabled: true };
    }

    if (action.type === DEACTIVATE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsActive: false };
    }

    if (action.type === ACTIVATE_AUDIO_STREAM) {
        state = { ...state, audioStreamIsActive: true };
    }

    if (action.type === MUTE_AUDIO) {
        state = { ...state, audioIsMuted: true };
    }

    if (action.type === UNMUTE_AUDIO) {
        state = { ...state, audioIsMuted: false };
    }

    if (action.type === MUTE_CALLER_MIC) {
        state = { ...state, callerMicIsMuted: true };
    }

    if (action.type === UNMUTE_CALLER_MIC) {
        state = { ...state, callerMicIsMuted: false };
    }

    if (action.type === ENABLE_GPS) {
        state = { ...state, gpsIsDisabled: false };
    }

    if (action.type === DISABLE_GPS) {
        state = { ...state, gpsIsDisabled: true };
    }

    if (action.type === DEACTIVATE_GPS) {
        state = { ...state, gpsIsActive: false };
    }

    if (action.type === ACTIVATE_GPS) {
        state = { ...state, gpsIsActive: true };
    }

    if (action.type === ENABLE_SNAPSHOT) {
        state = { ...state, snapshotIsDisabled: false };
    }

    if (action.type === DISABLE_SNAPSHOT) {
        state = { ...state, snapshotIsDisabled: true };
    }

    if (action.type === DEACTIVATE_SNAPSHOT) {
        state = { ...state, snapshotIsActive: false };
    }

    if (action.type === ACTIVATE_SNAPSHOT) {
        state = { ...state, snapshotIsActive: true };
    }

    if (action.type === PAUSE_SNAPSHOT) {
        state = { ...state, snapshotPaused: true };
    }

    if (action.type === UNPAUSE_SNAPSHOT) {
        state = { ...state, snapshotPaused: false };
    }

    if (action.type === ENABLE_POINTER) {
        state = { ...state, pointerIsDisabled: false };
    }

    if (action.type === DISABLE_POINTER) {
        state = { ...state, pointerIsDisabled: true };
    }

    if (action.type === DEACTIVATE_POINTER) {
        state = { ...state, pointerIsActive: false };
    }

    if (action.type === ACTIVATE_POINTER) {
        state = { ...state, pointerIsActive: true };
    }

    if (action.type === ENABLE_DRAW) {
        state = { ...state, drawIsDisabled: false };
    }

    if (action.type === DISABLE_DRAW) {
        state = { ...state, drawIsDisabled: true };
    }

    if (action.type === DEACTIVATE_DRAW) {
        state = { ...state, drawIsActive: false };
    }

    if (action.type === ACTIVATE_DRAW) {
        state = { ...state, drawIsActive: true };
    }

    if (action.type === ENABLE_CONFERENCING) {
        state = { ...state, conferencingIsDisabled: false };
    }

    if (action.type === DISABLE_CONFERENCING) {
        state = { ...state, conferencingIsDisabled: true };
    }

    if (action.type === ENABLE_BIDI) {
        state = { ...state, bidiIsDisabled: false };
    }

    if (action.type === DISABLE_BIDI) {
        state = { ...state, bidiIsDisabled: true };
    }

    if (action.type === ACTIVATE_BIDI) {
        state = { ...state, bidiIsActive: true };
    }

    if (action.type === DEACTIVATE_BIDI) {
        state = { ...state, bidiIsActive: false };
    }

    if (action.type === ACTIVATE_BIDI_BLUR) {
        state = { ...state, bidiBlurIsActive: true };
    }

    if (action.type === DEACTIVATE_BIDI_BLUR) {
        state = { ...state, bidiBlurIsActive: false };
    }

    if (action.type === ACTIVATE_BIDI_BACKGROUND) {
        state = { ...state, bidiBackgroundIsActive: true };
    }

    if (action.type === DEACTIVATE_BIDI_BACKGROUND) {
        state = { ...state, bidiBackgroundIsActive: false };
    }

    if (action.type === DEACTIVATE_HD_SEND) {
        state = { ...state, hdSendIsActive: false };
    }

    if (action.type === ACTIVATE_HD_SEND) {
        state = { ...state, hdSendIsActive: true };
    }

    if (action.type === CALLER_CHAT_SHOW) {
        state = { ...state, callerChatIsShown: true };
    }

    if (action.type === CALLER_CHAT_HIDE) {
        state = { ...state, callerChatIsShown: false };
    }

    if (action.type === ADD_VIDEO_STREAM) {
        state = { ...state, hasVideoStream: true };
    }

    if (action.type === REMOVE_VIDEO_STREAM) {
        state = { ...state, hasVideoStream: false };
    }

    if (action.type === ADD_MEDIA_DEVICES) {
        state = {
            ...state,
            devices: [...action.devices],
        };
    }

    if (action.type === CHANGE_DEVICE_ID) {
        state = {
            ...state,
            deviceId: action.deviceId,
        };
    }

    if (action.type === REMOVE_DEVICE_ID) {
        state = {
            ...state,
            deviceId: null,
        };
    }

    if (action.type === CHANGE_DEVICE_NAME) {
        state = {
            ...state,
            deviceName: action.deviceName,
        };
    }

    if (action.type === REMOVE_DEVICE_NAME) {
        state = {
            ...state,
            deviceName: null,
        };
    }

    if (action.type === SHOW_REFRESH) {
        state = {
            ...state,
            refreshButtonShown: true,
        };
    }

    if (action.type === CALLER_FILE_IS_BUSY) {
        state = { ...state, callerFileIsBusy: true };
    }

    if (action.type === CALLER_FILE_IS_NOT_BUSY) {
        state = { ...state, callerFileIsBusy: false };
    }

    if (action.type === CALLER_FILE_TRANSFER_STARTED) {
        state = { ...state, callerFileTransfering: true };
    }

    if (action.type === CALLER_FILE_TRANSFER_ENDED) {
        state = { ...state, callerFileTransfering: false };
    }

    if (action.type === ACTIVATE_CALLER_FILE_TRANSFER) {
        state = { ...state, callerFileTransferIsActive: true };
    }

    if (action.type === DEACTIVATE_CALLER_FILE_TRANSFER) {
        state = { ...state, callerFileTransferIsActive: false };
    }

    if (action.type === CALLER_PAGE_LOADED) {
        state = { ...state, callerPageLoaded: true };
    }

    if (action.type === CALLER_PAGE_NOT_LOADED) {
        state = { ...state, callerPageLoaded: false };
    }

    if (action.type === CALLER_END_PAGE_LOADED) {
        state = { ...state, callerEndPageLoaded: true };
    }

    if (action.type === CALLER_END_PAGE_NOT_LOADED) {
        state = { ...state, callerEndPageLoaded: false };
    }

    if (action.type === CALLER_TAB_IN_FOCUS) {
        state = { ...state, callerTabIsInFocus: true };
    }

    if (action.type === CALLER_TAB_NOT_IN_FOCUS) {
        state = { ...state, callerTabIsInFocus: false };
    }

    if (action.type === ACTIVATE_NOTES) {
        state = { ...state, notesIsActive: true };
    }

    if (action.type === DEACTIVATE_NOTES) {
        state = { ...state, notesIsActive: false };
    }

    if (action.type === ENABLE_STREAM_RECORDING) {
        state = { ...state, streamRecordingIsDisabled: false };
    }

    if (action.type === DISABLED_STREAM_RECORDING) {
        state = { ...state, streamRecordingIsDisabled: true };
    }

    if (action.type === ACTIVATE_STREAM_RECORDING) {
        state = { ...state, streamRecordingIsActive: true };
    }

    if (action.type === DEACTIVATE_STREAM_RECORDING) {
        state = { ...state, streamRecordingIsActive: false };
    }

    if (action.type === ENABLE_AUTO_STREAM_RECORDING) {
        state = { ...state, autoRecordingIsDisabled: false };
    }

    if (action.type === DISABLE_AUTO_STREAM_RECORDING) {
        state = { ...state, autoRecordingIsDisabled: true };
    }

    if (action.type === START_STREAM_RECORDING) {
        state = { ...state, streamRecordingHasStarted: true };
    }

    if (action.type === STOP_STREAM_RECORDING) {
        state = { ...state, streamRecordingHasStarted: false };
    }

    if (action.type === ACTIVATE_WEB_RTC) {
        state = { ...state, webRtcIsActive: true };
    }

    if (action.type === DEACTIVATE_WEB_RTC) {
        state = { ...state, webRtcIsActive: false };
    }

    if (action.type === ACTIVATE_PHOTO_AUTO_DOWNLOAD) {
        state = { ...state, preventPhotoAutoDownload: false };
    }

    if (action.type === DEACTIVATE_PHOTO_AUTO_DOWNLOAD) {
        state = { ...state, preventPhotoAutoDownload: true };
    }

    if (action.type === SET_PASSWORD_CHANGE_SENT) {
        state = { ...state, passwordChangeSent: true };
    }

    if (action.type === ENABLE_SCREEN_SHARE) {
        state = { ...state, screenShareIsDisabled: false };
    }

    if (action.type === DISABLE_SCREEN_SHARE) {
        state = { ...state, screenShareIsDisabled: true };
    }

    if (action.type === ACTIVATE_SCREEN_SHARE) {
        state = { ...state, screenshareIsActive: true };
    }

    if (action.type === DEACTIVATE_SCREEN_SHARE) {
        state = { ...state, screenshareIsActive: false };
    }

    if (action.type === OPEN_INVITATION_PANEL) {
        state = { ...state, invitationPanelIsOpen: true };
    }

    if (action.type === CLOSE_INVITATION_PANEL) {
        state = { ...state, invitationPanelIsOpen: false };
    }

    if (action.type === SET_LIVE_VIDEO_IS_LOADING) {
        state = { ...state, videoIsLoading: true };
    }

    if (action.type === UNSET_LIVE_VIDEO_IS_LOADING) {
        state = { ...state, videoIsLoading: false };
    }

    if (action.type === ACTIVATE_CONFERENCE_CHAT) {
        state = { ...state, conferenceChatIsActive: true };
    }

    if (action.type === DEACTIVATE_CONFERENCE_CHAT) {
        state = { ...state, conferenceChatIsActive: false };
    }

    if (action.type === ACTIVATE_EXTERNAL_STREAM) {
        state = { ...state, externalStreamIsActive: true };
    }

    if (action.type === DEACTIVATE_EXTERNAL_STREAM) {
        state = { ...state, externalStreamIsActive: false };
    }

    if (action.type === ACTIVATE_SMART_CONNECT) {
        state = { ...state, smartConnectIsActive: true };
    }

    if (action.type === DEACTIVATE_SMART_CONNECT) {
        state = { ...state, smartConnectIsActive: false };
    }

    if (action.type === RESET_ALL) {
        state = { ...state, ...initialState };
    }


    return state;
};

export default applicationReducer;
